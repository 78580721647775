import React, { useState } from "react";
import styles from "../../component/Tabs/Tabs.css";

const Tabs = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const tabOne = [
    {
      id: 1,
      tabTitle: "Хэмжээ",
    },
  ];
  const tabTwo = [
    {
      id: 2,
      tabTitle: "Цахилгаан",
    },
  ];
  const tabThree = [
    {
      id: 3,
      tabTitle: "Бусад ",
    },
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  return (
    <div className="cOntainer">
      <div className="tabs">
        {tabOne.map((tab, i) => (
          <button
            className={styles.one}
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
          >
            {tab.tabTitle}
          </button>
        ))}
        {tabTwo.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
          >
            {tab.tabTitle}
          </button>
        ))}
        {tabThree.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div>
        {tabOne.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div className="spec-item">
                <div className="row-one">
                  <div>
                    <h1>Суудлын тоо</h1>
                    <p>5</p>
                  </div>
                  <div>
                    <h1>Өргөн</h1>
                    <p>1950мм</p>
                  </div>
                  <div>
                    <h1>Урт</h1>
                    <p>4905мм</p>
                  </div>
                  <div>
                    <h1>Газраас тэнхлэг хүртэлх зай</h1>
                    <p>180 - 230мм</p>
                  </div>
                  {/* <div>
                    <h1></h1>
                    <p>72 л</p>
                  </div> */}
                  <div>
                    <h1>Өөрийн жин</h1>
                    <p>2190кг</p>
                  </div>
                  {/* <div>
                    <h1>Нийт жин</h1>
                    <p>2945кг</p>
                  </div> */}
                </div>
                <div className="row-two">
                  <div className={styles["row-two"]}>
                    <div>
                      <h1>Тэнхлэг хоорондын зай</h1>
                      <p>2960мм</p>
                    </div>
                    {/* <div>
                      <h1>Traction Capacity</h1>
                      <p>2000 kg</p>
                    </div> */}
                    <div>
                      <h1>Өндөр</h1>
                      <p>1660мм</p>
                    </div>
                    <div>
                      <h1>Дугуйн хэмжээс</h1>
                      <p>255/45 R20</p>
                    </div>
                    <div>
                      <h1>Тээшний багтаамж</h1>
                      <p>560л - 1320л</p>
                    </div>
                    {/* <div>
                      <h1>Roof Load</h1>
                      <p>75 kg</p>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {tabTwo.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div className="spec-item">
                <div className="row-one">
                  <div>
                    <h1>Бүтэн цэнэгээр туулах зам</h1>
                    <p>631 км</p>
                  </div>
                  <div>
                    <h1>Баттерейн багтаамж</h1>
                    <p>106 кв/ц</p>
                  </div>
                  <div>
                    <h1>Цэнэглэх чадал</h1>
                    <p>100 kW</p>
                  </div>
                  <div>
                    <h1>Цэнэг зарцуулалт</h1>
                    <p>15.5 кв/ц-100 км</p>
                  </div>
                  <div>
                    <h1>Холимог зам дахь цэнэг зарцуулалт</h1>
                    <p>20.2 кв/ц-100 км</p>
                  </div>
                </div>
                <div className="row-two">
                  <div className={styles["row-two"]}>
                    <div>
                      <h1>Цэнэглэгч залгуур</h1>
                      <p>2 төрөлтэй</p>
                    </div>
                    <div>
                      <h1>Баттерейн төрөл</h1>
                      <p>Ternary lithium</p>
                    </div>
                    {/* <div>
                      <h1>Power outlet</h1>
                      <p>12V and 230V</p>
                    </div> */}
                    <div>
                      <h1>Хурдан цэнэглэх хугацаа(DC 380в)</h1>
                      <p>20-80%: 45 мин</p>
                    </div>
                    <div>
                      <h1>Энгийн горимд цэнэглэх хугацаа(AC 220в)</h1>
                      <p>20-100%: 10ц, 0-100%: 12ц</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {tabThree.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div className="spec-item">
                <div className="row-one">
                  <div>
                    <h1>0-100 км/ц</h1>
                    <p>4.4 сек</p>
                  </div>
                  <div>
                    <h1>Дээд хурд</h1>
                    <p>200 км/ц</p>
                  </div>
                  <div>
                    <h1>Хөдөлгүүрийн чадал</h1>
                    <p>489 м.х (360 кв)</p>
                  </div>
                  <div>
                    <h1>Мушгилтын хүч</h1>
                    <p>720н.м (урдаа 310н.м, хойноо 410н.м）</p>
                  </div>
                  <div>
                    <h1>Эргэлтийн диаметр</h1>
                    <p>11.5 м</p>
                  </div>
                </div>
                <div className="row-two">
                  <div className={styles["row-two"]}>
                    <div>
                      <h1>Эргэлтийн радиус</h1>
                      <p>5.75 м</p>
                    </div>
                    <div>
                      <h1>Хөтлөгч</h1>
                      <p>4WD</p>
                    </div>
                    <div>
                      <h1>Хийн амортизатор</h1>
                      <p>100мм MAX</p>
                    </div>
                    <div>
                      <h1>Дугуйн хэмжээ</h1>
                      <p>255/45 R20</p>
                    </div>
                    <div>
                      <h1>Жолоодлогын горим</h1>
                      <p>
                        6 төрлийн горим: Eco, Comfort, Performance, Outing, Snow
                        and Individual
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
