import React from "react";
import styles from "./interior.module.css";
import interior1 from "../../static/img/one.png";
import interior2 from "../../static/img/two.jpg";
import interior3 from "../../static/img/three.png";
import { Link } from "react-router-dom";
import { CiCircleChevRight } from "react-icons/ci";
function Interior() {
  return (
    <div className={styles["wrap"]}>
      <h1>Интерьер дизайн</h1>
      <div className={styles["container"]}>
        <div className={styles["sub-container"]}>
          <img className={styles["pic"]} src={interior1} alt="" />
          <p className={styles["title"]}>
            Тансаг зэрэглэлийн бүрэн цахилгаан VOYAH FREE загварт зөвхөн дээд
            зэрэглэлийн материал ашигладаг.
            {/* <br></br>incididunt ut labore et
            dolore magna aliqua. <br></br>Ut enim ad minim */}
          </p>
        </div>
        <img className={styles["pic2"]} src={interior2} alt="" />{" "}
        <img className={styles["pic3"]} src={interior3} alt="" />
        <Link to="/voyah/free/inter">
          {/* <CiCircleChevRight className={styles["bigIcon"]} size={70} /> */}
        </Link>
      </div>
    </div>
  );
}

export default Interior;
