import Home from "../../pages/Home";
import { useLocation } from "react-router-dom";

export function Layout() {
  const location = useLocation();
  if (location.pathname.includes("/home")) {
    return (
      <>
        <Home></Home>
      </>
    );
  }
}
export default Layout;
