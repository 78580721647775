import MyRouter from "./routers/router";
import "./App.css";
import Layout from "./pages/Layout";

function App() {
  return (
    <div>
      <MyRouter>
        <Layout></Layout>
      </MyRouter>
    </div>
  );
}

export default App;
