import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
const quickLinks = [
  {
    path: "/about",
    display: "About",
  },

  {
    path: "#",
    display: "Privacy Policy",
  },

  {
    path: "/cars",
    display: "Car Listing",
  },
  {
    path: "/blogs",
    display: "Blog",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];
const Footer = () => {
  return (
    // <section className='section-grid'>
    <div className="footer">
      <div className="container">
        <div className="col">
          <div>
            <h2>Mership</h2>
          </div>
          <p className="footer__logo-content"></p>
        </div>
        <div className="col">
          <h4>Vehicles</h4>

          <p className="p-0 mt-3 quick__link">
            <Link to="/aeolus/shinegs">Aeolus ShineGs</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="/aeolus/huge">Aeolus Huge</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="/forthing/T5evo">Forthing T5 EVO</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="/forthing/yacht">Forthing Yacht</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="/voyah/dreamer">Voyah Dreamer</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="/voyah/free/">Voyah Free</Link>
          </p>
        </div>

        <div className="col">
          <h4>Quick Links</h4>

          <p className="p-0 mt-3 quick__link">
            <Link to="">Home</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Models</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Aeolus</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">FAQ</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Forthing</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Voyah</Link>
          </p>
        </div>

        <div className="col">
          <h4>About us</h4>

          <p className="p-0 mt-3 quick__link">
            <Link to="">Contact</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Location</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Models</Link>
          </p>
        </div>

        <div className="col">
          <h4>Social Links</h4>

          <p className="p-0 mt-3 quick__link">
            <Link to="">Facebook</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Instagram</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Twitter</Link>
          </p>
          <p className="p-0 mt-3 quick__link">
            <Link to="">Linkedin</Link>
          </p>
        </div>
      </div>
      <div className="details">
        <div className="bottom">
          <p className="gap1">All right recerved © 2023 mership</p>
          <div className="mership">
            <p className="gap">
              &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Terms
              & Condition
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy
              Policy
            </p>
          </div>
        </div>
      </div>
    </div>
    // </section>
  );
};

export default Footer;
