import styles from "../Inter/inter.module.css";
import { Link } from "react-router-dom";
import React from "react";
// import arrow from "../../assets/arrow.svg";
import Int from "../../component/Int/index";
import Mership from "../../assets/mership.png";
import main1 from "../../assets/main-1.png";
import main2 from "../../assets/main-2.png";
import int1 from "../../assets/int1.jpeg";
import int2 from "../../assets/int2.jpeg";
import int3 from "../../assets/int3.jpeg";
import int4 from "../../assets/int4.png";
import int5 from "../../assets/int5.jpg";
import int6 from "../../assets/int6.png";
import int7 from "../../assets/int7.jpeg";
import int8 from "../../assets/int8.jpeg";
import Footer from "../../component/Footer/Footer";

export function Voyah() {
  window.onscroll = function () {
    var header = document.getElementById("subheader_fixed");
    setSticky(window.pageYOffset);
  };
  const [sticky, setSticky] = React.useState(0);
  return (
    <div>
      <div
        id="subheader_fixed"
        className={sticky > 100 ? styles.sticky : ""}
        style={{ display: "none" }}
      >
        {" "}
        <div className={styles["logo"]}>
          {" "}
          <Link to="/home">
            <img src={Mership} className={styles["mership"]} alt="#"></img>
          </Link>
        </div>
        <div className={styles.subs}>
          {" "}
          <h1>Voyah Free</h1>
          <div className={styles["option"]}>
            <Link to="/voyah/free">
              <h3>Overview</h3>
            </Link>
            <Link to="/voyah/free/inter">
              <h3>Interior</h3>
            </Link>
            <Link to="/voyah/free/feature">
              <h3>Feature</h3>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles["subheader"]}>
        <h1>Voyah Free</h1>
        <div className={styles["option"]}>
          <Link to="/voyah/free">
            <h3>Overview</h3>
          </Link>
          <Link to="/voyah/free/inter">
            <h3>Interior</h3>
          </Link>
          <Link to="/voyah/free/feature">
            <h3>Feature</h3>
          </Link>
        </div>
      </div>

      <div className={styles["main-two"]}>
        <img src={main1} alt="asdasd" />
        <img src={main2} alt="asdasd" />
      </div>
      {/* <Int>
        {title}
        {subtitle}
        {text}
      </Int> */}
      <div className={styles["interior-text"]}>
        <div className={styles["container"]}>
          <h2>
            Тансаг зэрэглэлийн бүрэн цахилгаан VOYAH FREE загварт зөвхөн дээд
            зэрэглэлийн материал ашигладаг.
          </h2>
        </div>
      </div>
      <div className={styles["three-box"]}>
        <div className={styles["container"]}>
          <div className={styles["cards"]}>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={int1} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Хийцлэл</h3>
                <p className={styles["pi1"]}>
                  Салоны хийцлэлд модон болон хөнгөн цагаан оруулганы хослол
                  тансаг зэрэглийн илэрхийлэмж болж өгсөн.
                </p>
              </div>
            </div>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={int2} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Ухаалаг камер</h3>
                <p className={styles["pi1"]}>
                  5G дэмждэг ухаалаг камер нь таны нүүрийг таньж(Face ID)
                  автомашин жолоодлогын горимд шилжинэ.
                </p>
              </div>
            </div>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={int3} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Танд тустай</h3>
                <p className={styles["pi1"]}>
                  Түгжрээнд хөл амраах Auto-hold болон цахилгаан гар тормозтой.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["vehicles"]}>
        <div className={styles["contain"]}>
          <h1>Интерьер дизайн </h1>
          <div className={styles["containe"]}>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int4} alt="asdasd" />
                <p>
                  13 спикер бүхий Дани улсын алдарт Dynaudio хөгжмийн системийн
                  дуугаралтыг чанарын алдагдалгүй сонсох боломжтой.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int2} alt="asdasd" />
                <p>
                  Төгс систем, нарийн ширхэгт тоосонцор PM2.5 шүүхээс гадна
                  CHANEL брендийн сүрчигийг өөрийн хүслээр тохируулан үнэртүүлэх
                  боломжтой.
                </p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int8} alt="asdasd" />
                <p>
                  Урд 2 суудал халах, хөрөх мөн массаж хийх үйлдлүүдийг танд
                  үзүүлэх болно. Мөн хүний бие хийгээд байгаль орчинд хор
                  нөлөөгүй салоны шийдэл.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int6} alt="asdasd" />
                <p>
                  2D эсвэл 3D 5D төрлөөр тохируулах боломжтой 360 градус
                  камерийн систем, мөн урд болон хойно зай мэдрэгч байршсан. 3D
                  360 градус камертай.
                </p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int5} alt="asdasd" />
                <p>
                  Панаромик нээлхий нь шинэ шийдэл бүхий гэрэл бууруулагчтайгаас
                  гадна хар болон цагаан өнгөөр нарны шууд тусгалаас таныг
                  хамгаалах болно.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int7} alt="asdasd" />
                <p>
                  Арьсан бүрээс бүхий олон үйлдэлт, хагас спорт жолооны хүрдтэй.
                  Жолоочын өнцгөөс үзэгдэх орчин сайтай.12.5 инчийн дижитал
                  хянах самбар, газрын зураг, шатахуун зарцуулалтын мэдээлэл,
                  дугуйн хийн даралт гэх мэт өөрт хэрэгцээт мэдээллүүдээ
                  тохируулан гаргах боломжтой.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["email"]}>
        <div className={styles["Con"]}>
          <div className={styles["mail"]}>
            <p>
              Stay up to date on all about our latest news, updates and
              adventures.
            </p>
          </div>
          <div>
            <input
              className={styles["inputt"]}
              type="email"
              placeholder="Enter your email"
            />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
export default Voyah;
