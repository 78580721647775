import styles from "./free.module.css";
import React from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Exterior from "../../../../component/Exterior/Exterior";
import Interior from "../../../../component/Interior/Interior";
import Gallery from "../../../../component/Gallery/Gallery";
import Mership from "../../../../assets/mership.png";
import arrow from "../../../../assets/arrow.svg";
import Tabs from "../../../../component/Tabs/index";
import promo from "../../../../assets/promo.png";
import free1 from "../../../../assets/free1.jpg";
import exterior1 from "../../../../static/img/exterior1.png";
import voyah1 from "../../../../assets/voyah1.png";
import voyah2 from "../../../../assets/voyah2.jpg";
import voyah3 from "../../../../assets/voyah3.png";
import zurag from "../../../../assets/zurag.png";
import vyh from "../../../../assets/vyh.jpg";
import z1 from "../../../../assets/1.png";
import z2 from "../../../../assets/2.png";
import z3 from "../../../../assets/3.png";
import z4 from "../../../../assets/4.png";
import z5 from "../../../../assets/5.png";
import z6 from "../../../../assets/6.png";
import imgg from "../../../../assets/imgg.png";
import hero from "../../../../assets/hero.jpg";
import video1 from "../../../../assets/video1.mp4";
import vid1 from "../../../../assets/vid1.mp4";
import video from "../../../../assets/video.mp4";
import Footer from "../../../../component/Footer/Footer";

export function Voyah() {
  window.onscroll = function () {
    var header = document.getElementById("subheader_fixed");
    setSticky(window.pageYOffset);
  };
  const [showModal, setShowModal] = React.useState(false);
  const [selImg, setSelImg] = React.useState(1);
  const [sticky, setSticky] = React.useState(0);
  return (
    <div>
      <div
        id="subheader_fixed"
        className={sticky > 100 ? styles.sticky : ""}
        style={{ display: "none" }}
      >
        {" "}
        <div className={styles["logo"]}>
          {" "}
          <Link to="/home">
            <img src={Mership} className={styles["mership"]} alt="#"></img>
          </Link>
        </div>
        <div className={styles.subs}>
          {" "}
          <h1>Voyah Free</h1>
          <div className={styles["option"]}>
            <Link to="/voyah/free">
              <h3>Overview</h3>
            </Link>
            <Link to="/voyah/free/inter">
              <h3>Interior</h3>
            </Link>
            <Link to="/voyah/free/feature">
              <h3>Feature</h3>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles["subheader"]}>
        <h1>Voyah Free</h1>
        <div className={styles["option"]}>
          <Link to="/voyah/free">
            <h3>Overview</h3>
          </Link>
          <Link to="/voyah/free/inter">
            <h3>Interior</h3>
          </Link>
          <Link to="/voyah/free/feature">
            <h3>Feature</h3>
          </Link>
        </div>
      </div>

      <div className={styles["hero"]}>
        <img src={hero} alt="asdasd" />
      </div>
      <div className={styles["hero2"]}>
        <div className={styles.co}>
          <div className={styles["zurag1"]}>
            <p>Current Promotions</p>
            <img src={promo} className={styles.promo} alt="asdasd" />

            <img src={arrow} className={styles.arrow} alt="asdasd" />
          </div>
          <div className={styles["zrg"]}>
            <div className={styles["zurag2"]}>
              <p>Voyah Free ready for delivery before the new year</p>
              <img src={free1} alt="asdasd" />
              <img src={arrow} className={styles.arrow} alt="asdasd" />
            </div>
            <div className={styles["zurag3"]}>
              <p>Voyah showroom</p>
              <img src={exterior1} alt="asdasd" />
              <img src={arrow} className={styles.arrow} alt="asdasd" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles["main-image"]}>
        <img src={zurag} className={styles["image"]} alt="asdasd" />
      </div>

      <div className={styles["content2"]}>
        <div className={styles["conn"]}>
          <div className={styles["show"]}>
            <span className={styles["first-text"]}>860</span>
            <span className={styles["sec-text"]}>км</span>
            <p className={styles["p1"]}>Бүтэн цэнэгээр туулах зам</p>
          </div>
          <div className={styles["show"]}>
            <span className={styles["first-text"]}>490</span>
            <span className={styles["sec-text"]}>HP</span>
            <p className={styles["p1"]}>Хөдөлгүүрийн хүч</p>
          </div>
          <div className={styles["show"]}>
            <span className={styles["first-text"]}>4</span>
            <span className={styles["sec-text"]}>WD</span>
            <p className={styles["p1"]}>Хөтлөгч</p>
          </div>
          <div className={styles["show"]}>
            <span className={styles["first-text"]}>4.4</span>
            <span className={styles["sec-text"]}>сек</span>
            <p className={styles["p1"]}>Хурд авалт( 0-100km/h)</p>
          </div>
        </div>
      </div>
      <div className={styles["ext"]}>
        <div className={styles["CoO"]}>
          <div className={styles["name"]}>
            <p>Гадна өнгөний сонголт</p>
          </div>
          <div className={styles["colors"]}>
            <div
              className={
                styles.color + " " + (selImg == 1 ? styles.select1 : "")
              }
              style={{ backgroundColor: "#DFDFDF" }}
              onClick={() => setSelImg(1)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 2 ? styles.select2 : "")
              }
              style={{ backgroundColor: "#8ed1fc" }}
              onClick={() => setSelImg(2)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 3 ? styles.select3 : "")
              }
              style={{ backgroundColor: "#7c7c7c" }}
              onClick={() => setSelImg(3)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 4 ? styles.select4 : "")
              }
              style={{ backgroundColor: "#63803a" }}
              onClick={() => setSelImg(4)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 5 ? styles.select5 : "")
              }
              style={{ backgroundColor: "#0d4671" }}
              onClick={() => setSelImg(5)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 6 ? styles.select6 : "")
              }
              style={{ backgroundColor: "black" }}
              onClick={() => setSelImg(6)}
            ></div>
          </div>
          {selImg == 1 ? (
            <h1 className={styles["car-name"]}>Snow White</h1>
          ) : null}
          {selImg == 1 ? <img src={z1} /> : null}

          {selImg == 2 ? (
            <h1 className={styles["car-name"]}>Cloud Blue</h1>
          ) : null}
          {selImg == 2 ? <img src={z2} /> : null}
          {selImg == 3 ? (
            <h1 className={styles["car-name"]}>Cloud Gray</h1>
          ) : null}
          {selImg == 3 ? <img src={z3} /> : null}
          {selImg == 4 ? (
            <h1 className={styles["car-name"]}>Dark Green</h1>
          ) : null}
          {selImg == 4 ? <img src={z4} /> : null}
          {selImg == 5 ? (
            <h1 className={styles["car-name"]}>Sky Blue</h1>
          ) : null}
          {selImg == 5 ? <img src={z5} /> : null}
          {selImg == 6 ? (
            <h1 className={styles["car-name"]}>Star Night</h1>
          ) : null}
          {selImg == 6 ? <img src={z6} /> : null}
        </div>
      </div>
      <div className={styles["hero1"]}>
        {/* <img src={vfree} alt="asdasd" /> */}
      </div>
      {/* <div>
        <video src={video1} autoPlay loop muted />
        <div className={styles["vid"]}>
          <video src={vid1} autoPlay loop muted />
          <video src={video} autoPlay loop muted />
          <video src={video1} autoPlay loop muted />
        </div>
      </div> */}
      <Interior></Interior>
      <div className={styles["mid"]}>
        <img className={styles["vyh"]} src={vyh} alt="asdasd" />
      </div>
      <Exterior> </Exterior>
      <div className={styles["specs"]}>
        <div className={styles["ContaineR"]}>
          <div className={styles["title"]}>
            <span className={styles["first"]}>Техникийн үзүүлэлт</span>
            {/* <span className={styles["second"]}> үзүүлэлт</span> */}
          </div>
          <div className={styles["spec-img"]}>
            <img src={imgg} alt="asdasd" />
          </div>
          <Tabs></Tabs>
        </div>
      </div>
      <Gallery></Gallery>
      <div className={styles["text-content"]}>
        <div className={styles["coo"]}>
          <div className={styles["t1"]}>
            <span className={styles["f1"]}>Lorem ipsum dolor sit </span>
            <span className={styles["f2"]}>
              {" "}
              amet,consectetur adipiscing elit
            </span>
            <div className={styles["grid"]}>
              <div>
                <div className={styles["part1"]}>
                  <span className={styles["first-num"]}>200</span>
                  <span className={styles["second-value"]}>km/h</span>
                </div>
                <p>Max speed</p>
                <div className={styles["part1"]}>
                  <span className={styles["first-num"]}>100</span>
                  <span className={styles["second-value"]}>kW</span>
                </div>
                <p>Battery Capacity</p>
              </div>
              <div>
                <div className={styles["part1"]}>
                  <span className={styles["first-num"]}>12</span>
                  <span className={styles["second-value"]}>h</span>
                </div>
                <p>Home charging 0-100%</p>
                <div className={styles["part1"]}>
                  <span className={styles["first-num"]}>45</span>
                  <span className={styles["second-value"]}>min</span>
                </div>
                <p>Speed fast charge 20-80%</p>
              </div>
            </div>
          </div>
          <div className={styles["t2"]}>
            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Egestas maecenas pharetra convallis posuere. Integer vitae justo
              eget magna fermentum iaculis eu.Condimentum id venenatis a
              condimentum. Condimentum id venenatis a condimentum.
            </p>
          </div>
          <div className={styles["t3"]}>
            <h2>Lorem ipsum dolor sit amet, consectetur </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Egestas maecenas pharetra convallis posuere. Integer vitae justo
              eget magna fermentum iaculis eu.Condimentum id venenatis a
              condimentum. Condimentum id venenatis a condimentum.Condimentum id
              venenatis a condimentum
            </p>
          </div>
        </div>
      </div>
      <div className={styles["order"]}>
        <div className={styles["order-inside"]}>
          <div className={styles["order-now"]}>
            <h1>Order Voyah Free</h1>
            <div className={styles["btn"]}>
              <button className={styles["btn1"]}>Buy now</button>
              <button className={styles["btn2"]}>Custom Order</button>
            </div>
          </div>
          <div className={styles["order-img"]}>
            <img src={z5} alt="asdasd" />
          </div>
        </div>
      </div>
      <div className={styles["voyah-free"]}>
        <div className={styles["Container"]}>
          <h1>Voyah Free</h1>
          <p className={styles["free-text"]}>
            Технологийн дэвшилтэт шийдлүүдийг тав тухтай хослуулсан Free
            загварыг бүтээхдээ VOYAH брэндийн инженерүүд огтхон ч гар татаагүй.
            Учир нь түүнд цахилгаан автомашины зах зээлд тэргүүлэх зэрэглэлийн
            баттерей болон цахилгаан моторын шийдлүүд, хүний бие хийгээд байгаль
            орчинд хор нөлөөгүй салоны шийдэл, Дани улсын DYNAUDIO бүхий
            дуугaралтын систем гээд дэлхий дахинд хүлээн зөвшөөрөгдсөн бүхий л
            тоноглолууд бий.
          </p>
          <div className={styles["cards"]}>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={voyah1} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Lorem ipsum dolor sit amet, </h3>
                <p className={styles["pi1"]}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Egestas maecenas pharetra convallis posuere.
                </p>
              </div>
            </div>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={voyah2} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Lorem ipsum dolor sit amet,</h3>
                <p className={styles["pi1"]}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod{" "}
                </p>
              </div>
            </div>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={voyah3} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Lorem ipsum dolor sit amet, </h3>
                <p className={styles["pi1"]}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Egestas maecenas pharetra convallis posuere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["vehicles"]}>
        <div className={styles["contain"]}>
          <h1>Vehicles Excepteur sint occaecat </h1>
          <p>
            Libero volutpat sed cras ornare arcu. Arcu non odio euismod lacinia.
            Turpis cursus in hac habitasse platea dictumst quisque sagittis
            purus. Id velit ut tortor pretium viverra. Sed viverra tellus in hac
            habitasse platea dictumst vestibulum rhoncus.
          </p>
          <div className={styles["container"]}>
            <div className={styles["box"]}>
              <h2>Voyah Free</h2>
              <div className={styles["imgBox"]}>
                <Link to="/voyah/free">
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
              </div>
            </div>
            <div className={styles["box1"]}>
              <h2>Voyah Dreamer</h2>
              <div className={styles["imgBox"]}>
                <Link to="/voyah/dreamer">
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
              </div>
            </div>
            <div className={styles["box2"]}>
              <h2>Forthing T5 EVO</h2>
              <div className={styles["imgBox"]}>
                <Link to="/forthing/T5evo">
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
              </div>
            </div>
            <div className={styles["box3"]}>
              <h2>Aeolus ShineGs</h2>
              <div className={styles["imgBox"]}>
                <Link to="/aeolus/shinegs">
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["Btn"]}>
        <button>See more</button>
      </div>

      <video className={styles["voyah-video"]} src={vid1} autoPlay loop muted />
      {/* <div className={styles["Event"]}>
        <div className={styles["containeR"]}>
          <div className={styles["description"]}>
            <h1>Events</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>

            <div className={styles["pagination"]}>
              <a href="#">&laquo;</a>
              <a href="#">1</a>
              <a href="#" class="active">
                2
              </a>
              <a href="#">3</a>
              <a href="#">4</a>
              <a href="#">5</a>
              <a href="#">6</a>
              <a href="#">&raquo;</a>
            </div>
          </div>
          <div className={styles["Card1"]}>
            <div className={styles["card-image1"]}>
              <img src={event1} className={styles["img"]} alt="img" />
              <p>Lorem ipsum dolor sit amet</p>
              <button> read more</button>
            </div>
          </div>
          <div className={styles["Card1"]}>
            <div className={styles["card-image1"]}>
              <img src={event2} className={styles["img"]} alt="img" />
              <p>Lorem ipsum dolor sit amet, dolor sit amet, consectetur</p>
              <button> read more</button>
            </div>
          </div>
        </div>
      </div> */}

      <div className={styles["email"]}>
        <div className={styles["Con"]}>
          <div className={styles["mail"]}>
            <p>
              Stay up to date on all about our latest news, updates and
              adventures.
            </p>
          </div>
          <div>
            <input
              className={styles["inputt"]}
              type="email"
              placeholder="Enter your email"
            />
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
export default Voyah;
