import styles from "./subhome.module.css";
import { useLocation } from "react-router-dom";
// import img_voyah from "../../assets/voyah.png";
import img_forthing from "../../assets/forthing.jpg";
import img_aeolus from "../../assets/aeolus.jpg";
import logo from "../../assets/voyah-logo.png";

export function SubHome() {
  const location = useLocation();
  console.log(location.pathname);
  if (location.pathname.includes("/voyah")) {
    return (
      <div>
        <div className={styles["main"]}>
          <div className={styles["content"]}>
            <img src={logo} className={styles["voyah"]}></img>
            <p>High-end new energy brand of the national team</p>
          </div>
          {/* <img src={img_voyah} className={styles["img"]} alt="img" /> */}
        </div>
      </div>
    );
  } else if (location.pathname.includes("/forthing")) {
    return (
      <div>
        <div className={styles["main1"]}>
          <img src={img_forthing} className={styles["img"]} alt="img" />
        </div>
      </div>
    );
  } else if (location.pathname.includes("/aeolus")) {
    return (
      <div>
        <div className={styles["main2"]}>
          <img src={img_aeolus} className={styles["img"]} alt="img" />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default SubHome;
