import React from "react";
import Carousel from "react-multi-carousel";
import "./exterior.css";
import "react-multi-carousel/lib/styles.css";
import search from "../../static/img/voyah8.png";
import search1 from "../../static/img/voyah1.png";
import search2 from "../../static/img/voyah2.png";
import search3 from "../../static/img/voyah7.png";
import search4 from "../../static/img/voyah6.png";
import search5 from "../../static/img/voyah3.png";
import search6 from "../../static/img/voyah4.png";
import search7 from "../../static/img/voyah5.png";
import search8 from "../../static/img/voyah9.png";
import search9 from "../../static/img/voyah10.png";
import search10 from "../../static/img/voyah11.png";
import search11 from "../../static/img/voyah12.png";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class Exterior extends React.Component {
  state = { additionalTransfrom: 0 };
  render() {
    const CustomSlider = ({ carouselState }) => {
      let value = 0;
      let carouselItemWidth = 0;
      if (this.Carousel) {
        carouselItemWidth = this.Carousel.state.itemWidth;
        const maxTranslateX = Math.round(
          carouselItemWidth *
            (this.Carousel.state.totalItems -
              this.Carousel.state.slidesToShow) +
            150
        );
        value = maxTranslateX;
      }
      const { transform } = carouselState;
      return (
        <div className="custom-slider">
          <input
            type="range"
            value={Math.round(Math.abs(transform) / value)}
            defaultValue={0}
            max={
              (carouselItemWidth *
                (carouselState.totalItems - carouselState.slidesToShow) +
                (this.state.additionalTransfrom === 150 ? 0 : 150)) /
              value
            }
            onChange={(e) => {
              if (this.Carousel.isAnimationAllowed) {
                this.Carousel.isAnimationAllowed = false;
              }
              const nextTransform = e.target.value * value;
              const nextSlide = Math.round(nextTransform / carouselItemWidth);
              if (
                e.target.value == 0 &&
                this.state.additionalTransfrom === 150
              ) {
                this.Carousel.isAnimationAllowed = true;
                this.setState({ additionalTransfrom: 0 });
              }
              this.Carousel.setState({
                transform: -nextTransform,
                currentSlide: nextSlide,
              });
            }}
            className="custom-slider__input"
          />
        </div>
      );
    };
    return (
      <div className="wrapper">
        <h1 className="exterior">Гадна тал</h1>
        <Carousel
          ssr={false}
          ref={(el) => (this.Carousel = el)}
          partialVisbile={false}
          customButtonGroup={<CustomSlider />}
          itemClassName="slider-image-item"
          responsive={responsive}
          containerClass="carousel-container-with-scrollbar"
          additionalTransfrom={-this.state.additionalTransfrom}
          beforeChange={(nextSlide) => {
            if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
              this.setState({ additionalTransfrom: 150 });
            }
            if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
              this.setState({ additionalTransfrom: 0 });
            }
          }}
        >
          <div class="increase-size">
            <img
              className="size"
              //  draggable={false}
              src={search1}
            />
            <p className="text">
              Та бидний сайн мэдэх Lamborghini, Maseratti, Lotus гэх
              автомашинуудын загварыг гаргасан "Italdesign Giugiaro S.p.A."
              компани VOYAH FREE автомашин дээр ажиллаж тансаг зэрэглэлийн
              дарвуулт онгоцыг билэгдэн уг загварыг бүтээжээ.
            </p>
          </div>
          <div className="increase-size">
            <img
              className="size"
              draggable={false}
              // style={{ cursor: "pointer" }}
              src={search2}
            />
          </div>

          <div class="increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search3}
            />
          </div>

          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search4}
            />
          </div>

          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search5}
            />
          </div>
          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search}
            />
          </div>
          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search6}
            />
          </div>
          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search7}
            />
          </div>
          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search8}
            />
          </div>
          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search9}
            />
          </div>
          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search10}
            />
          </div>
          <div class=" increase-size">
            <img
              className="size"
              // draggable={false}
              style={{ cursor: "pointer" }}
              src={search11}
            />
          </div>
        </Carousel>
      </div>
    );
  }
}

export default Exterior;
