import styles from "../Feature/feature.module.css";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import React from "react";
// import "react-multi-carousel/lib/styles.css";
import Footer from "../../component/Footer/Footer";
import Mership from "../../assets/mership.png";
import main1 from "../../assets/main-1.png";
import main2 from "../../assets/main-2.png";
import int1 from "../../assets/int1.jpeg";
import int2 from "../../assets/int2.jpeg";
import int3 from "../../assets/int3.jpeg";
import int4 from "../../assets/int4.png";
import int5 from "../../assets/int5.jpg";
import int6 from "../../assets/int6.png";
import int7 from "../../assets/int7.jpeg";
import int8 from "../../assets/int8.jpeg";

export function Voyah() {
  window.onscroll = function () {
    var header = document.getElementById("subheader_fixed");
    setSticky(window.pageYOffset);
  };
  const [sticky, setSticky] = React.useState(0);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 524, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      <div
        id="subheader_fixed"
        className={sticky > 100 ? styles.sticky : ""}
        style={{ display: "none" }}
      >
        {" "}
        <div className={styles["logo"]}>
          {" "}
          <Link to="/home">
            <img src={Mership} className={styles["mership"]} alt="#"></img>
          </Link>
        </div>
        <div className={styles.subs}>
          {" "}
          <h1>Voyah Free</h1>
          <div className={styles["option"]}>
            <Link to="/voyah/free">
              <h3>Overview</h3>
            </Link>
            <Link to="/voyah/free/inter">
              <h3>Interior</h3>
            </Link>
            <Link to="/voyah/free/feature">
              <h3>Feature</h3>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles["subheader"]}>
        <h1>Voyah Free</h1>
        <div className={styles["option"]}>
          <Link to="/voyah/free">
            <h3>Overview</h3>
          </Link>
          <Link to="/voyah/free/inter">
            <h3>Interior</h3>
          </Link>
          <Link to="/voyah/free/feature">
            <h3>Features</h3>
          </Link>
        </div>
      </div>
      <div className={styles["main-two"]}>
        <img src={main1} alt="asdasd" />
        <img src={main2} alt="asdasd" />
      </div>
      <div className={styles["vehicles"]}>
        <div className={styles["contain"]}>
          <h1>Infotainment </h1>
          <div className={styles["containe"]}>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int4} alt="asdasd" />
                <p>Sound quality: DYNAUDIO HIFI Sound System</p>
              </div>
              <div className={styles["box"]}>
                <img src={int2} alt="asdasd" />
                <p>Navigation & map function</p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int8} alt="asdasd" />
                <p>
                  Урд 2 суудал халах, хөрөх мөн массаж хийх үйлдлүүдийг танд
                  үзүүлэх болно. Мөн хүний бие хийгээд байгаль орчинд хор
                  нөлөөгүй салоны шийдэл.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int6} alt="asdasd" />
                <p>DAB radio</p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int5} alt="asdasd" />
                <p>
                  Панаромик нээлхий нь шинэ шийдэл бүхий гэрэл бууруулагчтайгаас
                  гадна хар болон цагаан өнгөөр нарны шууд тусгалаас таныг
                  хамгаалах болно.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int7} alt="asdasd" />
                <p>Display: Three raise/lower 12.3″ screens in the dashboard</p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int2} alt="asdasd" />
                <p>
                  Панаромик нээлхий нь шинэ шийдэл бүхий гэрэл бууруулагчтайгаас
                  гадна хар болон цагаан өнгөөр нарны шууд тусгалаас таныг
                  хамгаалах болно.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int4} alt="asdasd" />
                <p>Display: Three raise/lower 12.3″ screens in the dashboard</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["mid-two"]}>
        <h1>Sustainability</h1>
        <div className={styles["mid-two-container"]}>
          <div>
            <img src={main1} alt="asdasd" />
            <p>Display: Three raise/lower 12.3″ screens in the dashboard</p>
          </div>
          <div>
            <img src={main2} alt="asdasd" />
            <p>Display: Three raise/lower 12.3″ screens in the dashboard</p>
          </div>
        </div>
      </div>
      <div className={styles["vehicles"]}>
        <div className={styles["contain"]}>
          <h1>Comfort </h1>
          <div className={styles["containe"]}>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int4} alt="asdasd" />
                <p>
                  13 спикер бүхий Дани улсын алдарт Dynaudio хөгжмийн системийн
                  дуугаралтыг чанарын алдагдалгүй сонсох боломжтой.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int2} alt="asdasd" />
                <p>
                  Төгс систем, нарийн ширхэгт тоосонцор PM2.5 шүүхээс гадна
                  CHANEL брендийн сүрчигийг өөрийн хүслээр тохируулан үнэртүүлэх
                  боломжтой.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int2} alt="asdasd" />
                <p>
                  Төгс систем, нарийн ширхэгт тоосонцор PM2.5 шүүхээс гадна
                  CHANEL брендийн сүрчигийг өөрийн хүслээр тохируулан үнэртүүлэх
                  боломжтой.
                </p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int8} alt="asdasd" />
                <p>
                  Урд 2 суудал халах, хөрөх мөн массаж хийх үйлдлүүдийг танд
                  үзүүлэх болно. Мөн хүний бие хийгээд байгаль орчинд хор
                  нөлөөгүй салоны шийдэл.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int8} alt="asdasd" />
                <p>
                  Урд 2 суудал халах, хөрөх мөн массаж хийх үйлдлүүдийг танд
                  үзүүлэх болно. Мөн хүний бие хийгээд байгаль орчинд хор
                  нөлөөгүй салоны шийдэл.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int6} alt="asdasd" />
                <p>
                  2D эсвэл 3D 5D төрлөөр тохируулах боломжтой 360 градус
                  камерийн систем, мөн урд болон хойно зай мэдрэгч байршсан. 3D
                  360 градус камертай.
                </p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int5} alt="asdasd" />
                <p>
                  Панаромик нээлхий нь шинэ шийдэл бүхий гэрэл бууруулагчтайгаас
                  гадна хар болон цагаан өнгөөр нарны шууд тусгалаас таныг
                  хамгаалах болно.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int7} alt="asdasd" />
                <p>
                  Арьсан бүрээс бүхий олон үйлдэлт, хагас спорт жолооны хүрдтэй.
                  Жолоочын өнцгөөс үзэгдэх орчин сайтай.12.5 инчийн дижитал
                  хянах самбар, газрын зураг, шатахуун зарцуулалтын мэдээлэл,
                  дугуйн хийн даралт гэх мэт өөрт хэрэгцээт мэдээллүүдээ
                  тохируулан гаргах боломжтой.
                </p>
              </div>
              <div className={styles["box"]}>
                <img src={int7} alt="asdasd" />
                <p>
                  Арьсан бүрээс бүхий олон үйлдэлт, хагас спорт жолооны хүрдтэй.
                  Жолоочын өнцгөөс үзэгдэх орчин сайтай.12.5 инчийн дижитал
                  хянах самбар, газрын зураг, шатахуун зарцуулалтын мэдээлэл,
                  дугуйн хийн даралт гэх мэт өөрт хэрэгцээт мэдээллүүдээ
                  тохируулан гаргах боломжтой.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.safety}>
        <div className={styles.container}>
          <div className={styles["safety-img"]}>
            <img src={int7} alt="asdasd" />
          </div>
          <div className={styles["safety-text"]}>
            <h1>Safety</h1>
            <ul>
              <li>LED headlights with various light functions</li>
              <li>Monitoring: Driver safety and in the back seat</li>
              <li>
                Airbags: Two in the front, two in the back of the front seats
                and two side curtains covering the front and rear seats
              </li>
              <li>Collision warning</li>
              <li>Tire pressure warning</li>
              <li>Body: 75% high strength steel</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.carousel}>
        <div className={styles.inside}>
          <h1>Driving assistant</h1>
          <Carousel responsive={responsive}>
            <div className={styles.drive}>
              <img src={int7} alt="asdasd" />
              <p>Traffic jam assistant</p>
            </div>
            <div className={styles.drive}>
              <img src={int2} alt="asdasd" />
              <p>Traffic jam assistant</p>
            </div>
            <div className={styles.drive}>
              <img src={int3} alt="asdasd" />
              <p>Traffic jam assistant</p>
            </div>
            <div className={styles.drive}>
              <img src={int4} alt="asdasd" />
              <p>Traffic jam assistant</p>
            </div>
            <div className={styles.drive}>
              <img src={int5} alt="asdasd" />
              <p>Traffic jam assistant</p>
            </div>
            <div className={styles.drive}>
              <img src={int6} alt="asdasd" />
              <p>Traffic jam assistant</p>
            </div>
          </Carousel>
        </div>
      </div>
      <div className={styles.safety}>
        <div className={styles.container}>
          <div className={styles["safety-text"]}>
            <h1>Other practical equipmentand solutions</h1>
            <ul>
              <li>Heated rear window and side mirrors</li>
              <li>Isofix: Two rear seats</li>
              <li>Key: Remote key and VOYAH app</li>
              <li>
                Boot: Retractable cover and floor protection in vegan leather
              </li>
              <li>Floor mats: Fabric mats on the floor front and back</li>
              <li>Prepared for trailer attachment</li>
            </ul>
          </div>
          <div className={styles["safety-img"]}>
            <img src={int7} alt="asdasd" />
          </div>
        </div>
      </div>
      <div className={styles["email"]}>
        <div className={styles["Con"]}>
          <div className={styles["mail"]}>
            <p>
              Stay up to date on all about our latest news, updates and
              adventures.
            </p>
          </div>
          <div>
            <input
              className={styles["inputt"]}
              type="email"
              placeholder="Enter your email"
            />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
export default Voyah;
