import React from "react";
import styles from "../../pages/Inter/inter.module.css";
import int1 from "../../assets/int1.jpeg";
import int2 from "../../assets/int2.jpeg";
import int3 from "../../assets/int3.jpeg";
import int4 from "../../assets/int4.png";
import int5 from "../../assets/int5.jpg";
import int6 from "../../assets/int6.png";
import int7 from "../../assets/int7.jpeg";
import int8 from "../../assets/int8.jpeg";

export function Voyah({ subtitle, title, description, text }) {
  return (
    <>
      <div className={styles["interior-text"]}>
        <div className={styles["container"]}>
          <h2>{title}</h2>
        </div>
      </div>
      <div className={styles["three-box"]}>
        <div className={styles["container"]}>
          <div className={styles["cards"]}>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={int1} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>{subtitle}</h3>
                <p className={styles["pi1"]}>{text}</p>
              </div>
            </div>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={int2} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>{subtitle}</h3>
                <p className={styles["pi1"]}>{text}</p>
              </div>
            </div>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={int3} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>{subtitle}</h3>
                <p className={styles["pi1"]}>{text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["vehicles"]}>
        <div className={styles["contain"]}>
          <h1>{title} </h1>
          <div className={styles["containe"]}>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int4} alt="asdasd" />
                <p>{description}</p>
              </div>
              <div className={styles["box"]}>
                <img src={int2} alt="asdasd" />
                <p>{description}</p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int8} alt="asdasd" />
                <p>{description}</p>
              </div>
              <div className={styles["box"]}>
                <img src={int6} alt="asdasd" />
                <p>{description}</p>
              </div>
            </div>
            <div className={styles.big}>
              {" "}
              <div className={styles["box"]}>
                <img src={int5} alt="asdasd" />
                <p>{description}</p>
              </div>
              <div className={styles["box"]}>
                <img src={int7} alt="asdasd" />
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Voyah;
