import styles from "./About.module.css";
import video from "../../assets/video.mp4";
import video1 from "../../assets/video1.mp4";
import vdreamer from "../../assets/vdreamer.png";
import ahuge from "../../assets/ahuge.png";
import about1 from "../../assets/about1.png";
import Mership from "../../assets/mership.png";
import { Link } from "react-router-dom";
import vid1 from "../../assets/vid1.mp4";
import { useLocation } from "react-router-dom";
import Footer from "../../component/Footer/Footer";
import React from "react";

export function About() {
  window.onscroll = function () {
    var header = document.getElementById("subheader_fixed");
    setSticky(window.pageYOffset);
  };
  const [sticky, setSticky] = React.useState(0);
  const location = useLocation();
  console.log(location.pathname);
  if (location.pathname.includes("/voyah/about")) {
    return (
      <div>
        <div
          id="subheader_fixed"
          className={sticky > 100 ? styles.sticky : ""}
          style={{ display: "none" }}
        >
          {" "}
          <div className={styles["logo"]}>
            {" "}
            <Link to="/home">
              <img src={Mership} className={styles["mership"]} alt="#"></img>
            </Link>
          </div>
          <div className={styles.subs}>
            {" "}
            <h1>About</h1>
            {/* <div className={styles["option"]}>
              <Link to="/voyah/free">
                <h3>Overview</h3>
              </Link>
              <Link to="/voyah/free/inter">
                <h3>Interior</h3>
              </Link>
              <Link to="/voyah/free/feature">
                <h3>Feature</h3>
              </Link>
            </div> */}
          </div>
        </div>
        <div className={styles["subheader"]}>
          <h1>About</h1>
          {/* <div className={styles["option"]}>
            <Link to="/voyah/free">
              <h3>Overview</h3>
            </Link>
            <Link to="/voyah/free/inter">
              <h3>Interior</h3>
            </Link>
            <Link to="/voyah/free/feature">
              <h3>Feature</h3>
            </Link>
          </div> */}
        </div>
        <div className={styles["head"]}>
          <div className={styles["head-contain"]}>
            <div className={styles.story}>
              <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
            </div>
            <div className={styles.vision}>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Egestas maecenas pharetra convallis posuere.
              </p>
            </div>
          </div>
        </div>
        <div className={styles["about-img"]}>
          {" "}
          <img src={about1} alt="asdasd" />
        </div>
        <div className={styles["about-us"]}>
          <div className={styles["about"]}>
            <div className={styles.story}>
              <h1>Our Story</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Egestas maecenas pharetra convallis posuere. Integer vitae justo
                eget magna fermentum iaculis eu.Condimentum id venenatis a
                condimentum. Condimentum id venenatis a condimentum.
              </p>
            </div>
            <div className={styles.vision}>
              <h1>Our vision</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Egestas maecenas pharetra convallis posuere. Integer vitae justo
                eget magna fermentum iaculis eu.Condimentum id venenatis a
                condimentum. Condimentum id venenatis a condimentum.
              </p>
            </div>
          </div>
        </div>
        <div className={styles["content2"]}>
          <div className={styles["conn"]}>
            <div className={styles["show"]}>
              <span className={styles["first-text"]}>
                Lorem ipsum dolor sit amet, consectetur
              </span>
              {/* <span className={styles["sec-text"]}>км</span> */}
              <p className={styles["p1"]}>200+</p>
            </div>
            <div className={styles["show"]}>
              <span className={styles["first-text"]}>
                Lorem ipsum dolor sit amet, consectetur
              </span>
              {/* <span className={styles["sec-text"]}>HP</span> */}
              <p className={styles["p1"]}>100+</p>
            </div>
            <div className={styles["show"]}>
              <span className={styles["first-text"]}>
                Lorem ipsum dolor sit amet, consectetur
              </span>
              {/* <span className={styles["sec-text"]}>WD</span> */}
              <p className={styles["p1"]}>20+</p>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div className={styles["container"]}>
            <div className={styles["pic"]}>
              {" "}
              <img src={ahuge} alt="asdasd" />
            </div>
            <div className={styles["text"]}>
              <h1>Showroom</h1>
              <p>
                HUGE загвар нь зай багтаамж томтойн дээр технологийн олон
                шийдлийг өөртөө агуулсан их хотын залуусын хэрэгцээнд нийцсэн
                шилдэг бүтээлүүдийн нэг юм.  
              </p>
            </div>
          </div>
          <div className={styles["container1"]}>
            <div className={styles["pic1"]}>
              {" "}
              <img src={vdreamer} alt="asdasd" />
            </div>
            <div className={styles["text"]}>
              <h1>Office</h1>
              <p>
                Таны ажил, амьдрал мөн аялалын хэрэгцээнд зориулсан ухаалаг,
                тансаг зэрэглэлийн, бүрэн цахилгаан MPV.
              </p>
            </div>
          </div>
        </div>
        <div className={styles["about-us"]}>
          <div className={styles["about"]}>
            <div className={styles.story}>
              <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
            </div>
            <div className={styles.vision}>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Egestas maecenas pharetra convallis posuere. Integer vitae justo
                eget magna fermentum iaculis eu.Condimentum id venenatis a
                condimentum. Condimentum id venenatis a condimentum.
              </p>
            </div>
          </div>
        </div>
        <div className={styles["about-img"]}>
          {" "}
          <img src={about1} alt="asdasd" />
        </div>
        <div className={styles["email"]}>
          <div className={styles["Con"]}>
            <div className={styles["mail"]}>
              <p>
                Stay up to date on all about our latest news, updates and
                adventures.
              </p>
            </div>
            <div>
              <input
                className={styles["inputt"]}
                type="email"
                placeholder="Enter your email"
              />
            </div>
          </div>
        </div>
        <Footer></Footer>;
      </div>
    );
  } else if (location.pathname.includes("/forthing/about")) {
    // return (
    //   <div>
    //     <div className="video">
    //       <video src={video1} autoPlay loop muted />
    //       <div className="title">
    //         {" "}
    //         {/* <h2>Voyah</h2>
    //         <p>Dongfeng Motor Group's high-end electric brand</p> */}
    //       </div>
    //     </div>
    //     <div className={styles["content"]}>
    //       <div className={styles["list"]}>
    //         <ul className={styles["items"]}>
    //           <li className={styles["item"]}>53 years car build </li>
    //           <li className={styles["item"]}>85 bit Fortune 500 </li>
    //           <li className={styles["item"]}>
    //             20 years New energy technology{" "}
    //           </li>
    //           <li className={styles["item"]}>Strong partner </li>
    //         </ul>
    //       </div>
    //     </div>
    //     <div className={styles["section"]}>
    //       <div className={styles["container"]}>
    //         <h2 className={styles["title"]}>Voyah брэндийн түүх</h2>
    //         <p className={styles["text"]}>
    //           Voyah нь Хятадын төрийн өмчит автомашин үйлдвэрлэгч Dongfeng Motor
    //           Corporation -ын тансаг зэрэглэлийн дэд брэнд юм. Voyah (岚图) нь
    //           цахилгаан машин зохион бүтээх, хөгжүүлэх чиглэлээр мэргэшсэн
    //           автомашин үйлдвэрлэгч юм. Брэндийн 2020 оны нээлтийн үер Voyah
    //           I-Free болон I-Land концепц танилцуулагдсан. Voyah автомашины
    //           загварыг ItalDesign -тай хамтран боловсруулсан. Хоёр концепцийн
    //           машин нь дараагийн жилүүдэд Voyah-ын үйлдвэрлэсэн автомашинуудыг
    //           танилцуулж байгаа бөгөөд анхны концепц болон үйлдвэрлэлийн
    //           машинууд бүгд Dongfeng-ийн Essa цахилгаан платформ дээр
    //           бүтээгдсэн. Voyah брэндийг 2020 оны 9-р сард 2020 оны Бээжингийн
    //           авто шоуны үеэр i-Free болон i-Land концепцуудын хамт худалдаанд
    //           гаргасан.
    //         </p>
    //       </div>
    //     </div>
    //     <div className={styles["section1"]}>
    //       <div className={styles["inner"]}>
    //         <h1>Ухаалаг технологиуд</h1>
    //         <p className={styles["text1"]}>
    //           Энэхүү машин нь OTA автомат программын шинэчлэлт, жолоочийг
    //           эгнээнд явахад туслах, автомат тоормос, замын панорам зураг,
    //           шөнийн хараа гэх мэт 20 ухаалаг функцтэй. Автомат жолоодлогын L2
    //           түвшинтэй. 24 ухаалаг мэдрэгч суурилуулсан, шөнийн харааны
    //           идэвхтэй систем нь явган зорчигч болон зам саадыг урьдчилан
    //           анхааруулдаг. Дөрвөн төрлийн дуут дохио, команд мэдэрдэг
    //           дэлгэцтэй. Гурван том форматтай дэлгэц нь хяналтын, мэдээллийн,
    //           дэлгэцийн үүргийг гүйцэтгэдэг. Жолооны горимыг солих үед дэлгэцийг
    //           өргөж, буулгаж, өнгийг нь өөрчлөх боломжтой.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // );
  } else if (location.pathname.includes("/aeolus/about")) {
    // return (
    //   <div>
    //     <div className="video">
    //       <video src={vid1} autoPlay loop muted />
    //       <div className="title">
    //         {" "}
    //         {/* <h2>Voyah</h2>
    //         <p>Dongfeng Motor Group's high-end electric brand</p> */}
    //       </div>
    //     </div>
    //     <div className={styles["content"]}>
    //       <div className={styles["list"]}>
    //         <ul className={styles["items"]}>
    //           <li className={styles["item"]}>53 years car build </li>
    //           <li className={styles["item"]}>85 bit Fortune 500 </li>
    //           <li className={styles["item"]}>
    //             20 years New energy technology{" "}
    //           </li>
    //           <li className={styles["item"]}>Strong partner </li>
    //         </ul>
    //       </div>
    //     </div>
    //     <div className={styles["section"]}>
    //       <div className={styles["container"]}>
    //         <h2 className={styles["title"]}>Voyah брэндийн түүх</h2>
    //         <p className={styles["text"]}>
    //           Voyah нь Хятадын төрийн өмчит автомашин үйлдвэрлэгч Dongfeng Motor
    //           Corporation -ын тансаг зэрэглэлийн дэд брэнд юм. Voyah (岚图) нь
    //           цахилгаан машин зохион бүтээх, хөгжүүлэх чиглэлээр мэргэшсэн
    //           автомашин үйлдвэрлэгч юм. Брэндийн 2020 оны нээлтийн үер Voyah
    //           I-Free болон I-Land концепц танилцуулагдсан. Voyah автомашины
    //           загварыг ItalDesign -тай хамтран боловсруулсан. Хоёр концепцийн
    //           машин нь дараагийн жилүүдэд Voyah-ын үйлдвэрлэсэн автомашинуудыг
    //           танилцуулж байгаа бөгөөд анхны концепц болон үйлдвэрлэлийн
    //           машинууд бүгд Dongfeng-ийн Essa цахилгаан платформ дээр
    //           бүтээгдсэн. Voyah брэндийг 2020 оны 9-р сард 2020 оны Бээжингийн
    //           авто шоуны үеэр i-Free болон i-Land концепцуудын хамт худалдаанд
    //           гаргасан.
    //         </p>
    //       </div>
    //     </div>
    //     <div className={styles["section1"]}>
    //       <div className={styles["inner"]}>
    //         <h1>Ухаалаг технологиуд</h1>
    //         <p className={styles["text1"]}>
    //           Энэхүү машин нь OTA автомат программын шинэчлэлт, жолоочийг
    //           эгнээнд явахад туслах, автомат тоормос, замын панорам зураг,
    //           шөнийн хараа гэх мэт 20 ухаалаг функцтэй. Автомат жолоодлогын L2
    //           түвшинтэй. 24 ухаалаг мэдрэгч суурилуулсан, шөнийн харааны
    //           идэвхтэй систем нь явган зорчигч болон зам саадыг урьдчилан
    //           анхааруулдаг. Дөрвөн төрлийн дуут дохио, команд мэдэрдэг
    //           дэлгэцтэй. Гурван том форматтай дэлгэц нь хяналтын, мэдээллийн,
    //           дэлгэцийн үүргийг гүйцэтгэдэг. Жолооны горимыг солих үед дэлгэцийг
    //           өргөж, буулгаж, өнгийг нь өөрчлөх боломжтой.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // );
  } else {
    return <div></div>;
  }
}
export default About;
