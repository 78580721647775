import styles from "./t5evo.module.css";
import React from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Exterior from "../../../component/Exterior/Exterior";
import Interior from "../../../component/Interior/Interior";
import Gallery from "../../../component/Gallery/Gallery";
import Mership from "../../../assets/mership.png";
import arrow from "../../../assets/arrow.svg";
// import t5 from "../../../assets/t5.jpg";
// import t5evo from "../../../assets/t5evo.png";
import b from "../../../assets/forthing-t5-evo/b.webp";
import c from "../../../assets/forthing-t5-evo/c.webp";
import f from "../../../assets/forthing-t5-evo/f.webp";
import t51 from "../../../assets/t51.png";
import t52 from "../../../assets/t52.png";
import t53 from "../../../assets/t53.png";
import t55 from "../../../assets/t55.png";
import tcover from "../../../assets/tcover.png";
import y5 from "../../../assets/forthing/y5.webp";
import y7 from "../../../assets/forthing/y7.webp";
import Tabs from "../../../component/Tabs/index";
import voyah1 from "../../../assets/voyah1.png";
import voyah2 from "../../../assets/voyah2.jpg";
import voyah3 from "../../../assets/voyah3.png";
import z1 from "../../../assets/1.png";
import z2 from "../../../assets/2.png";
import z3 from "../../../assets/3.png";
import z4 from "../../../assets/4.png";
import z5 from "../../../assets/5.png";
import z6 from "../../../assets/6.png";
import video1 from "../../../assets/video1.mp4";
import vid1 from "../../../assets/vid1.mp4";
import video from "../../../assets/video.mp4";
import Footer from "../../../component/Footer/Footer";
import interior1 from "../../../static/img/car.png";
import interior2 from "../../../static/img/car.png";
import interior3 from "../../../static/img/car.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import search from "../../../assets/forthing-t5-evo/t1.webp";
import search1 from "../../../assets/forthing-t5-evo/t2.webp";
import search2 from "../../../assets/forthing-t5-evo/t3.webp";
import search3 from "../../../assets/forthing-t5-evo/t4.webp";
import search4 from "../../../assets/forthing-t5-evo/t5.webp";
import search5 from "../../../assets/forthing-t5-evo/t6.webp";
import search6 from "../../../assets/forthing-t5-evo/t7.webp";
import search7 from "../../../assets/forthing-t5-evo/t8.webp";
import search8 from "../../../assets/forthing-t5-evo/t9.webp";
import search9 from "../../../assets/forthing-t5-evo/t1.webp";
import search10 from "../../../assets/forthing-t5-evo/t5.webp";
import search11 from "../../../static/img/forthing.png";
export function Voyah() {
  window.onscroll = function () {
    var header = document.getElementById("subheader_fixed");
    setSticky(window.pageYOffset);
  };
  // const [showModal, setShowModal] = React.useState(false);
  // const showMyModal = () => setShowModal(true);
  // const hideMyModal = () => setShowModal(false);
  const [selImg, setSelImg] = React.useState(1);
  const [sticky, setSticky] = React.useState(0);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  class Exterior extends React.Component {
    state = { additionalTransfrom: 0 };
    render() {
      const CustomSlider = ({ carouselState }) => {
        let value = 0;
        let carouselItemWidth = 0;
        if (this.Carousel) {
          carouselItemWidth = this.Carousel.state.itemWidth;
          const maxTranslateX = Math.round(
            carouselItemWidth *
              (this.Carousel.state.totalItems -
                this.Carousel.state.slidesToShow) +
              150
          );
          value = maxTranslateX;
        }
        const { transform } = carouselState;
        return (
          <div className="custom-slider">
            <input
              type="range"
              value={Math.round(Math.abs(transform) / value)}
              defaultValue={0}
              max={
                (carouselItemWidth *
                  (carouselState.totalItems - carouselState.slidesToShow) +
                  (this.state.additionalTransfrom === 150 ? 0 : 150)) /
                value
              }
              onChange={(e) => {
                if (this.Carousel.isAnimationAllowed) {
                  this.Carousel.isAnimationAllowed = false;
                }
                const nextTransform = e.target.value * value;
                const nextSlide = Math.round(nextTransform / carouselItemWidth);
                if (
                  e.target.value == 0 &&
                  this.state.additionalTransfrom === 150
                ) {
                  this.Carousel.isAnimationAllowed = true;
                  this.setState({ additionalTransfrom: 0 });
                }
                this.Carousel.setState({
                  transform: -nextTransform,
                  currentSlide: nextSlide,
                });
              }}
              className="custom-slider__input"
            />
          </div>
        );
      };
      return (
        <div className="wrapper">
          <h1 className="exterior">Гадна тал</h1>
          <Carousel
            ssr={false}
            ref={(el) => (this.Carousel = el)}
            partialVisbile={false}
            customButtonGroup={<CustomSlider />}
            itemClassName="slider-image-item"
            responsive={responsive}
            containerClass="carousel-container-with-scrollbar"
            additionalTransfrom={-this.state.additionalTransfrom}
            beforeChange={(nextSlide) => {
              if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
                this.setState({ additionalTransfrom: 150 });
              }
              if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
                this.setState({ additionalTransfrom: 0 });
              }
            }}
          >
            <div class="increase-size">
              <img
                className="size"
                //  draggable={false}
                src={search1}
              />
              <p className="text">
                Та бидний сайн мэдэх Lamborghini, Maseratti, Lotus гэх
                автомашинуудын загварыг гаргасан "Italdesign Giugiaro S.p.A."
                компани VOYAH FREE автомашин дээр ажиллаж тансаг зэрэглэлийн
                дарвуулт онгоцыг билэгдэн уг загварыг бүтээжээ.
              </p>
            </div>
            <div className="increase-size">
              <img
                className="size"
                draggable={false}
                // style={{ cursor: "pointer" }}
                src={search2}
              />
            </div>

            <div class="increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search3}
              />
            </div>

            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search4}
              />
            </div>

            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search5}
              />
            </div>
            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search}
              />
            </div>
            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search6}
              />
            </div>
            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search7}
              />
            </div>
            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search8}
              />
            </div>
            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search9}
              />
            </div>
            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search10}
              />
            </div>
            <div class=" increase-size">
              <img
                className="size"
                // draggable={false}
                style={{ cursor: "pointer" }}
                src={search11}
              />
            </div>
          </Carousel>
        </div>
      );
    }
  }
  function Interior() {
    return (
      <div className={styles["wrap"]}>
        <h1 className={styles["desc"]}>Интерьер дизайн</h1>
        <div className={styles["containerint"]}>
          <div className={styles["inner-container"]}>
            <img className={styles["pic"]} src={b} alt="" />
            <p className={styles["titleint"]}>
              Тансаг зэрэглэлийн бүрэн цахилгаан VOYAH FREE загварт зөвхөн дээд
              зэрэглэлийн материал ашигладаг.
              {/* <br></br>incididunt ut labore et
              dolore magna aliqua. <br></br>Ut enim ad minim */}
            </p>
          </div>
          <img className={styles["pic2"]} src={c} alt="" />{" "}
          <img className={styles["pic3"]} src={f} alt="" />
          <div>
            {/* <Link to='/voyah/free/inter'>
							<CiCircleChevRight className={styles["bigIcon"]} size={70} />
						</Link> */}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div
        id="subheader_fixed"
        className={sticky > 100 ? styles.sticky : ""}
        style={{ display: "none" }}
      >
        {" "}
        <div className={styles["logo"]}>
          {" "}
          <Link to="/home">
            <img src={Mership} className={styles["mership"]} alt="#"></img>
          </Link>
        </div>
        <div className={styles.subs}>
          {" "}
          <h1>Voyah Free</h1>
          <div className={styles["option"]}>
            <Link to="/forthing/T5evo">
              <h3>Overview</h3>
            </Link>
            <h3>Interior</h3>
            <h3>Feature</h3>
          </div>
        </div>
      </div>
      <div className={styles["subheader"]}>
        <h1>Forthing T5Evo</h1>
        <div className={styles["option"]}>
          <Link to="/forthing/T5evo">
            <h3>Overview</h3>
          </Link>
          <h3>Interior</h3>

          <h3>Features</h3>
        </div>
      </div>
      {/* {showModal ? <Modal1 /> : null} */}
      <div className={styles["hero"]}>
        <img src={t53} alt="asdasd" />
      </div>
      <div className={styles["hero2"]}>
        <div className={styles["co"]}>
          <div className={styles["zurag1"]}>
            <p>Current Promotions</p>
            <img src={t55} alt="asdasd" />
            <img src={arrow} className={styles.arrow} alt="asdasd" />
          </div>
          <div className={styles["zrg"]}>
            <div className={styles["zurag2"]}>
              <p>Voyah Free ready for delivery before the new year</p>
              <img src={y5} alt="asdasd" />
              <img src={arrow} className={styles.arrow} alt="asdasd" />
            </div>
            <div className={styles["zurag3"]}>
              <p>Voyah showroom</p>
              <img src={tcover} alt="asdasd" />
              <img src={arrow} className={styles.arrow} alt="asdasd" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles["main-image"]}>
        <img src={y7} className={styles["image"]} alt="asdasd" />
      </div>
      <div className={styles["content2"]}>
        <div className={styles["conn"]}>
          <div className={styles["show"]}>
            <span className={styles["first-text"]}>500</span>
            <span className={styles["sec-text"]}>km</span>
            <p className={styles["p1"]}>Range (WLTP)</p>
          </div>
          <div className={styles["show"]}>
            <span className={styles["first-text"]}>483</span>
            <span className={styles["sec-text"]}>HP</span>
            <p className={styles["p1"]}>Engine power</p>
          </div>
          <div className={styles["show"]}>
            <span className={styles["first-text"]}>4</span>
            <span className={styles["sec-text"]}>WD</span>
            <p className={styles["p1"]}>Wheel drive</p>
          </div>
          <div className={styles["show"]}>
            <span className={styles["first-text"]}>4.4</span>
            <span className={styles["sec-text"]}>sec</span>
            <p className={styles["p1"]}>0-100km/h</p>
          </div>
        </div>
      </div>
      <div className={styles["ext"]}>
        <div className={styles["CoO"]}>
          <div className={styles["name"]}>
            <p>Available in 6 colors</p>
          </div>
          <div className={styles["colors"]}>
            <div
              className={
                styles.color + " " + (selImg == 1 ? styles.select1 : "")
              }
              style={{ backgroundColor: "#DFDFDF" }}
              onClick={() => setSelImg(1)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 2 ? styles.select2 : "")
              }
              style={{ backgroundColor: "#8ed1fc" }}
              onClick={() => setSelImg(2)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 3 ? styles.select3 : "")
              }
              style={{ backgroundColor: "#7c7c7c" }}
              onClick={() => setSelImg(3)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 4 ? styles.select4 : "")
              }
              style={{ backgroundColor: "#63803a" }}
              onClick={() => setSelImg(4)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 5 ? styles.select5 : "")
              }
              style={{ backgroundColor: "#0d4671" }}
              onClick={() => setSelImg(5)}
            ></div>
            <div
              className={
                styles.color + " " + (selImg == 6 ? styles.select6 : "")
              }
              style={{ backgroundColor: "black" }}
              onClick={() => setSelImg(6)}
            ></div>
          </div>
          {selImg == 1 ? (
            <h1 className={styles["car-name"]}>Snow White</h1>
          ) : null}
          {selImg == 1 ? <img src={z1} /> : null}

          {selImg == 2 ? (
            <h1 className={styles["car-name"]}>Cloud Blue</h1>
          ) : null}
          {selImg == 2 ? <img src={z2} /> : null}
          {selImg == 3 ? (
            <h1 className={styles["car-name"]}>Cloud Gray</h1>
          ) : null}
          {selImg == 3 ? <img src={z3} /> : null}
          {selImg == 4 ? (
            <h1 className={styles["car-name"]}>Dark Green</h1>
          ) : null}
          {selImg == 4 ? <img src={z4} /> : null}
          {selImg == 5 ? (
            <h1 className={styles["car-name"]}>Sky Blue</h1>
          ) : null}
          {selImg == 5 ? <img src={z5} /> : null}
          {selImg == 6 ? (
            <h1 className={styles["car-name"]}>Star Night</h1>
          ) : null}
          {selImg == 6 ? <img src={z6} /> : null}
        </div>
      </div>
      <div className={styles["hero1"]}>
        {/* <img src={vfree} alt="asdasd" /> */}
      </div>
      {/* <div>
        <video src={video1} autoPlay loop muted />
        <div className={styles["vid"]}>
          <video src={vid1} autoPlay loop muted />
          <video src={video} autoPlay loop muted />
          <video src={video1} autoPlay loop muted />
        </div>
      </div> */}
      <Interior></Interior>
      <div className={styles["mid"]}>
        <img className={styles["vyh"]} src={t52} alt="asdasd" />
      </div>
      <Exterior> </Exterior>
      <div className={styles["specs"]}>
        <div className={styles["ContaineR"]}>
          <div className={styles["title"]}>
            <span className={styles["first"]}>Forthing T5Evo</span>
            <span className={styles["second"]}> Specs</span>
          </div>
          <div className={styles["spec-img"]}>
            <img src={t51} alt="asdasd" />
          </div>
          <Tabs></Tabs>
        </div>
      </div>
      {/* <Gallery></Gallery> */}
      <div className={styles["text-content"]}>
        <div className={styles["coo"]}>
          <div className={styles["t1"]}>
            <span className={styles["f1"]}>Lorem ipsum dolor sit </span>
            <span className={styles["f2"]}>
              {" "}
              amet,consectetur adipiscing elit
            </span>
            <div className={styles["grid"]}>
              <div>
                <div className={styles["part1"]}>
                  <span className={styles["first-num"]}>200</span>
                  <span className={styles["second-value"]}>km/h</span>
                </div>
                <p>Max speed</p>
                <div className={styles["part1"]}>
                  <span className={styles["first-num"]}>100</span>
                  <span className={styles["second-value"]}>kW</span>
                </div>
                <p>Battery Capacity</p>
              </div>
              <div>
                <div className={styles["part1"]}>
                  <span className={styles["first-num"]}>12</span>
                  <span className={styles["second-value"]}>h</span>
                </div>
                <p>Home charging 0-100%</p>
                <div className={styles["part1"]}>
                  <span className={styles["first-num"]}>45</span>
                  <span className={styles["second-value"]}>min</span>
                </div>
                <p>Speed fast charge 20-80%</p>
              </div>
            </div>
          </div>
          <div className={styles["t2"]}>
            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Egestas maecenas pharetra convallis posuere. Integer vitae justo
              eget magna fermentum iaculis eu.Condimentum id venenatis a
              condimentum. Condimentum id venenatis a condimentum.
            </p>
          </div>
          <div className={styles["t3"]}>
            <h2>Lorem ipsum dolor sit amet, consectetur </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Egestas maecenas pharetra convallis posuere. Integer vitae justo
              eget magna fermentum iaculis eu.Condimentum id venenatis a
              condimentum. Condimentum id venenatis a condimentum.Condimentum id
              venenatis a condimentum
            </p>
          </div>
        </div>
      </div>
      <div className={styles["order"]}>
        <div className={styles["order-inside"]}>
          <div className={styles["order-now"]}>
            <h1>Order Voyah Free</h1>
            <div className={styles["btn"]}>
              <button className={styles["btn1"]}>Buy now</button>
              <button className={styles["btn2"]}>Custom Order</button>
            </div>
          </div>
          <div className={styles["order-img"]}>
            <img src={z5} alt="asdasd" />
          </div>
        </div>
      </div>
      <div className={styles["voyah-free"]}>
        <div className={styles["Container"]}>
          <h1>Voyah Free</h1>
          <p className={styles["free-text"]}>
            Facilisi nullam vehicula ipsum a arcu cursus. Etiam non quam lacus
            suspendisse faucibus. Libero volutpat sed cras ornare arcu. Arcu non
            odio euismod lacinia. Turpis cursus in hac habitasse platea dictumst
            quisque sagittis purus. Id velit ut tortor pretium viverra. Sed
            viverra tellus in hac habitasse platea dictumst vestibulum rhoncus.
            Commodo quis imperdiet massa tincidunt nunc pulvinar sapien et.
            Ultricies mi quis hendrerit dolor magna eget est lorem.
          </p>
          <div className={styles["cards"]}>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={voyah1} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Lorem ipsum dolor sit amet, </h3>
                <p className={styles["pi1"]}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Egestas maecenas pharetra convallis posuere.
                </p>
              </div>
            </div>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={voyah2} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Lorem ipsum dolor sit amet, dolor sit amet, consectetur</h3>
                <p className={styles["pi1"]}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod{" "}
                </p>
              </div>
            </div>
            <div className={styles["Card"]}>
              <div className={styles["card-image"]}>
                <img src={voyah3} className={styles["img"]} alt="img" />
              </div>
              <div className={styles["text1"]}>
                <h3>Lorem ipsum dolor sit amet, </h3>
                <p className={styles["pi1"]}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Egestas maecenas pharetra convallis posuere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["vehicles"]}>
        <div className={styles["contain"]}>
          <h1>Vehicles Excepteur sint occaecat </h1>
          <p>
            Libero volutpat sed cras ornare arcu. Arcu non odio euismod lacinia.
            Turpis cursus in hac habitasse platea dictumst quisque sagittis
            purus. Id velit ut tortor pretium viverra. Sed viverra tellus in hac
            habitasse platea dictumst vestibulum rhoncus.
          </p>
          <div className={styles["container"]}>
            <div className={styles["box"]}>
              <h1>Voyah Free</h1>
              <div className={styles["imgBox"]}>
                <Link to="/voyah/free">
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
              </div>
            </div>
            <div className={styles["box1"]}>
              <h1>Voyah Dreamer</h1>
              <div className={styles["imgBox"]}>
                <Link to="/voyah/dreamer">
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
              </div>
            </div>
            <div className={styles["box2"]}>
              <h1>Forthing T5 EVO</h1>
              <div className={styles["imgBox"]}>
                <Link to="/forthing/T5evo">
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
              </div>
            </div>
            <div className={styles["box3"]}>
              <h1>Aeolus ShineGs</h1>
              <div className={styles["imgBox"]}>
                <Link to="/aeolus/shinegs">
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["Btn"]}>
        <button>See more</button>
      </div>

      <video className={styles["voyah-video"]} src={vid1} autoPlay loop muted />
      {/* <div className={styles["Event"]}>
			{/* <div className={styles["Event"]}>
        <div className={styles["containeR"]}>
          <div className={styles["description"]}>
            <h1>Events</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>

            <div className={styles["pagination"]}>
              <a href="#">&laquo;</a>
              <a href="#">1</a>
              <a href="#" class="active">
                2
              </a>
              <a href="#">3</a>
              <a href="#">4</a>
              <a href="#">5</a>
              <a href="#">6</a>
              <a href="#">&raquo;</a>
            </div>
          </div>
          <div className={styles["Card1"]}>
            <div className={styles["card-image1"]}>
              <img src={event1} className={styles["img"]} alt="img" />
              <p>Lorem ipsum dolor sit amet</p>
              <button> read more</button>
            </div>
          </div>
          <div className={styles["Card1"]}>
            <div className={styles["card-image1"]}>
              <img src={event2} className={styles["img"]} alt="img" />
              <p>Lorem ipsum dolor sit amet, dolor sit amet, consectetur</p>
              <button> read more</button>
            </div>
          </div>
        </div>
      </div> */}

      <div className={styles["email"]}>
        <div className={styles["Con"]}>
          <div className={styles["mail"]}>
            <p>
              Stay up to date on all about our latest news, updates and
              adventures.
            </p>
          </div>
          <div>
            <input
              className={styles["inputt"]}
              type="email"
              placeholder="Enter your email"
            />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
export default Voyah;
