import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "../component/Header";
import SubHome from "../pages/SubHome";
import T5evo from "../pages/Forthing/t5evo";
import Yacht from "../pages/Forthing/Yacht";
import Huge from "../pages/Aeolus/huge";
import Shinegs from "../pages/Aeolus/shinegs";
import About from "../pages/About";
import Home from "../pages/Layout";
import Free from "../pages/Voyah/Free/free";
import Dreamer from "../pages/Voyah/Free/dreamer";
import Inter from "../pages/Inter";
import Feature from "../pages/Feature";
const router = () => {
  return (
    <Router>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Navigate to="/home" element={<Home />} />} />
        <Route path="/home" element={<Home></Home>} />
        <Route path="/voyah" element={<SubHome></SubHome>} />
        <Route path="/forthing" element={<SubHome></SubHome>} />
        <Route path="/aeolus" element={<SubHome></SubHome>} />
        <Route path="/voyah/free" element={<Free></Free>} />
        <Route path="/voyah/dreamer" element={<Dreamer></Dreamer>} />
        <Route path="/voyah/about" element={<About></About>} />
        <Route path="voyah/free/inter" element={<Inter></Inter>} />
        <Route path="voyah/free/feature" element={<Feature></Feature>} />
        <Route path="/forthing/T5evo" element={<T5evo></T5evo>} />
        <Route path="/forthing/yacht" element={<Yacht></Yacht>} />
        <Route
          path="/forthing/about"
          element={<About about_text="about forthing"></About>}
        />
        <Route path="/aeolus/shinegs" element={<Shinegs></Shinegs>} />
        <Route path="/aeolus/huge" element={<Huge></Huge>} />
        <Route
          path="/aeolus/about"
          element={<About about_text="about aeoulus"></About>}
        />
      </Routes>
    </Router>
  );
};

export default router;
