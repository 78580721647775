import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import Mership from "../../assets/mership.png";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import dd from "../../assets/dd.png";
import free from "../../assets/free.jpg";
import t2 from "../../assets/forthing-t5-evo/t2.webp";
import yacht from "../../assets/yacht.jpg";
import shinegs from "../../assets/2021-Aeolus-Yixuan-GS-1.jpg";
import h1 from "../../assets/aeolus-huge/h1.jpg";
import huge from "../../assets/huge.jpg";
import arrow from "../../assets/arrow.svg";
import search from "../../assets/search-icon.svg";
import menu from "../../assets/menu.png";
import { FaBars, FaTimes } from "react-icons/fa";

export function Header() {
  const [sel_menu, setMenu] = React.useState(0);
  const [closemenu, closeMenu] = React.useState(0);
  const [shownav, showNav] = React.useState(0);

  return (
    <nav>
      <div className={styles["wrapper"]}>
        <div className={styles["logo"]} onClick={() => setMenu(0)}>
          {" "}
          <Link to="/home">
            <img src={Mership} className={styles["mership"]} alt="#"></img>
          </Link>
        </div>

        <div
          className={styles["brands"]}
          // + " " + (shownav === 0 ? "models" : "nav-links")
        >
          <ul className={styles["nav-links"]}>
            {" "}
            <li
              className={
                styles["models"] +
                " " +
                (sel_menu === 0 || sel_menu === 1 ? "" : styles.asd)
              }
            >
              Voyah
              <div
                className={
                  styles["mega-box"] +
                  " " +
                  (closemenu === 0 ? "" : styles.closemenu)
                }
                onClick={() => {
                  setMenu(1);
                }}
              >
                <div className={styles["content"]}>
                  <div
                    className={styles.row}
                    onClick={() => {
                      closeMenu(1);
                    }}
                  >
                    <Link to="/voyah/free">
                      <h2> Voyah Free</h2>
                      <img src={free} alt=""></img>
                      <img src={arrow} className={styles.arrow} alt="asdasd" />
                    </Link>
                    <Link to="/voyah/dreamer">
                      {" "}
                      <h2> Voyah Dreamer</h2>
                      <img src={dd} alt=""></img>
                      <img src={arrow} className={styles.arrow1} alt="asdasd" />
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul className={styles["nav-links"]}>
            {" "}
            <li
              className={
                styles["models"] +
                " " +
                (sel_menu == 0 || sel_menu == 2 ? "" : styles.asd)
              }
            >
              Forthing
              <label
                for="showMega"
                className={styles["mobile-item"]}
                onClick={() => setMenu(2)}
              ></label>
              <div
                className={
                  styles["mega-box"] +
                  " " +
                  (closemenu === 0 ? "" : styles.closemenu)
                }
                onClick={() => {
                  setMenu(1);
                }}
              >
                <div className={styles["content"]}>
                  <div
                    className={styles["row"]}
                    onClick={() => {
                      closeMenu(1);
                    }}
                  >
                    <Link to="/forthing/t5evo">
                      <h2> Forthing T5Evo</h2>
                      <img src={t2} alt=""></img>
                      <img src={arrow} className={styles.arrow} alt="asdasd" />
                    </Link>
                    <Link to="/forthing/yacht">
                      {" "}
                      <h2> Forthing Yacht</h2>
                      <img src={yacht} alt=""></img>
                      <img src={arrow} className={styles.arrow1} alt="asdasd" />
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul className={styles["nav-links"]}>
            {" "}
            <li
              className={
                styles["models"] +
                " " +
                (sel_menu == 0 || sel_menu == 3 ? "" : styles.asd)
              }
            >
              Aeolus
              <label
                for="showMega"
                className={styles["mobile-item"]}
                onClick={() => setMenu(3)}
              >
                {/* Aeolus */}
              </label>
              <div
                className={
                  styles["mega-box"] +
                  " " +
                  (closemenu === 0 ? "" : styles.closemenu)
                }
                onClick={() => {
                  setMenu(1);
                }}
              >
                <div className={styles["content"]}>
                  <div
                    className={styles["row"]}
                    onClick={() => {
                      closeMenu(1);
                    }}
                  >
                    <Link to="/aeolus/shinegs">
                      <h2> Aeolus ShineGs</h2>
                      <img src={shinegs} alt=""></img>
                      <img src={arrow} className={styles.arrow} alt="asdasd" />
                      <img src={arrow} className={styles.arrow1} alt="asdasd" />
                    </Link>
                    <Link to="/aeolus/huge">
                      {" "}
                      <h2> Aeolus Huge</h2>
                      <img src={h1} alt=""></img>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <img className={styles["search"]} src={search} alt=""></img>
          <button
            className={styles.btn}
            // onClick={() => {
            //   showNav(1);
            // }}
          >
            <FaBars></FaBars>
          </button>
          {/* <img src={menu} className={styles["toggle-button"]}></img>{" "} */}
        </div>
      </div>
    </nav>
  );
}

export default Header;
