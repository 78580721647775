// import "../../styles/home.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "../../component/Header";
import styles from "./home.module.css";
import la from "../../assets/aeolus-logo.png";
import lf from "../../assets/forthing-logo.png";
import lv from "../../assets/logo-voyah.png";
import Footer from "../../component/Footer/Footer";
import ahuge from "../../assets/ahuge.png";
import shine from "../../assets/shine.png";
import t5evo from "../../assets/t5evo.png";
import vdreamer from "../../assets/vdreamer.png";
import yacht from "../../assets/yacht.png";
import about1 from "../../assets/about1.png";
import dd from "../../assets/dd.png";
import free from "../../assets/free.jpg";
import tcover from "../../assets/tcover.png";
import a6 from "../../assets/a6.png";
import huge from "../../assets/huge.jpg";
// import event1 from "../../assets/event1.jpg";
// import event2 from "../../assets/event2.png";
import arrow from "../../assets/arrow.svg";
import t53 from "../../assets/t53.png";
import a4 from "../../assets/a4.png";

import { Link } from "react-router-dom";

export function Home() {
  const [showModal, setShowModal] = React.useState(false);
  const showMyModal = () => setShowModal(true);
  const hideMyModal = () => setShowModal(false);
  const Modal1 = () => (
    <div className={styles["brands"]}>
      <ul className={styles["nav-links"]}>
        {" "}
        <li className={styles["active"]}>
          <Link to="/voyah" className={styles["desktop-item"]}>
            Voyah
          </Link>
          {/*  */}
          <label for="showMega" className={styles["mobile-item"]}>
            Voyah
          </label>
          <div className={styles["mega-box"]}>
            <div className={styles["content"]}>
              <div className={styles["row"]}>
                <Link to="/voyah/free">
                  <h2> Voyah Free</h2>
                  <img src={free} alt=""></img>
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
                <Link to="/voyah/dreamer">
                  {" "}
                  <h2> Voyah Dreamer</h2>
                  <img src={dd} alt=""></img>
                  <img src={arrow} className={styles.arrow1} alt="asdasd" />
                </Link>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul className={styles["nav-links"]}>
        {" "}
        <li className={styles["models"]}>
          <Link to="/forthing" className={styles["desktop-item"]}>
            Forthing
          </Link>

          <label for="showMega" className={styles["mobile-item"]}>
            Forthing
          </label>
          <div className={styles["mega-box"]}>
            <div className={styles["content"]}>
              <div className={styles["row"]}>
                <Link to="/forthing/t5evo">
                  <h2> Forthing T5Evo</h2>
                  <img src={tcover} alt=""></img>
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                </Link>
                <Link to="/forthing/yacht">
                  {" "}
                  <h2> Forthing Yacht</h2>
                  <img src={yacht} alt=""></img>
                  <img src={arrow} className={styles.arrow1} alt="asdasd" />
                </Link>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul className={styles["nav-links"]}>
        {" "}
        <li className={styles["models"]}>
          <Link to="aeolus" className={styles["desktop-item"]}>
            Aeolus
          </Link>

          <label for="showMega" className={styles["mobile-item"]}>
            Aeolus
          </label>
          <div className={styles["mega-box"]}>
            <div className={styles["content"]}>
              <div className={styles["row"]}>
                <Link to="/aeolus/shinegs">
                  <h2> Aeolus ShineGs</h2>
                  <img src={a6} alt=""></img>
                  <img src={arrow} className={styles.arrow} alt="asdasd" />
                  <img src={arrow} className={styles.arrow1} alt="asdasd" />
                </Link>
                <Link to="/aeolus/huge">
                  {" "}
                  <h2> Aeolus Huge</h2>
                  <img src={huge} alt=""></img>
                </Link>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
  return (
    <>
      {showModal ? <Modal1 /> : null}
      <section>
        <div className={styles["home"]}>
          {/* <img src={home} alt="asdasd" /> */}
          <h1>WELCOME TO MERSHIP</h1>
          <div className={styles["btn"]}>
            <button className={styles["btn1"]}>Buy now</button>
            <button className={styles["btn2"]}>Custom Order</button>
          </div>
        </div>
      </section>
      <section className={styles["three-image"]}>
        <div className={styles["model"]}>
          <h1>AEOLUS</h1>
        </div>
        <div className={styles["model1"]}>
          <h1>VOYAH</h1>
        </div>
        <div className={styles["model2"]}>
          <h1>FORTHING</h1>
        </div>
      </section>
      <div className={styles["container"]}>
        <div className={styles["pic"]}>
          {" "}
          <img src={ahuge} alt="asdasd" />
        </div>
        <div className={styles["text"]}>
          <h1>AEOLUS HUGE</h1>
          <p>
            HUGE загвар нь зай багтаамж томтойн дээр технологийн олон шийдлийг
            өөртөө агуулсан их хотын залуусын хэрэгцээнд нийцсэн шилдэг
            бүтээлүүдийн нэг юм.  
          </p>
        </div>
      </div>
      <div className={styles["container1"]}>
        <div className={styles["pic1"]}>
          {" "}
          <img src={vdreamer} alt="asdasd" />
        </div>
        <div className={styles["text"]}>
          <h1>VOYAH DREAMER</h1>
          <p>
            Таны ажил, амьдрал мөн аялалын хэрэгцээнд зориулсан ухаалаг, тансаг
            зэрэглэлийн, бүрэн цахилгаан MPV.
          </p>
        </div>
      </div>
      <div className={styles["container"]}>
        <div className={styles["pic2"]}>
          {" "}
          <img src={t5evo} alt="asdasd" />
        </div>
        <div className={styles["text"]}>
          <h1>FORTHING T5 EVO</h1>
          <p>
            Их хотын залуусын хэрэгцээнд нийцүүлэн гаргасан загвар болох EVO T5
            нь өнгөний сонголтоос эхлээд түүний их биеийн хэлбэр хурц төрх яахын
            аргагүй залуусын сонголтыг илтгэх юм.
          </p>
        </div>
      </div>
      <div className={styles["container1"]}>
        <div className={styles["pic3"]}>
          {" "}
          <img src={shine} alt="asdasd" />
        </div>
        <div className={styles["text"]}>
          <h1>AEOLUS SHINE GS</h1>
          <p>
            Хот хөдөө хаана ч хамгийн сайн зохицох загварлаг, технологийн шинэ
            шийдлүүдийг бүгдийг багтааж чадсан Shine GS загвар нь 150 морины хүч
            гаргах чадалтай 1.5л бензин турбо хөдөлгүүртэй тул 100км-т ердөө
            7-9л шатахуун зарцуулна.
          </p>
        </div>
      </div>
      <div className={styles["container"]}>
        <div className={styles["pic4"]}>
          {" "}
          <img src={yacht} alt="asdasd" />
        </div>
        <div className={styles["text"]}>
          <h1>FORTHING YACHT </h1>
          <p>
            Гэр бүлийн болон албан ажлын хэрэгцээнд тохиромжтой бөгөөд зай
            багтаамж сайтай, шатахуун зарцуулалт багатай автомашин хайж байгаа
            бол Yacht таны хамгийн зөв сонголт байх болно.
          </p>
        </div>
      </div>

      <section className={styles["about-section"]}>
        <div className={styles.about}>
          <div className={styles["rightImg"]}>
            <img src={about1} alt="asdasd" />
          </div>
          <div className={styles["leftcontent"]}>
            <h1>About Mership llc</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam Duis aute irure dolor in reprehenderit in
              voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
            <Link to="/voyah/about">
              <img src={arrow} className={styles.arrow} alt="asdasd" />
            </Link>
          </div>
        </div>
      </section>
      {/* <div className={styles["Event"]}>
				<div className={styles["containeR"]}>
					<div className={styles["description"]}>
						<h1>Events</h1>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat.
						</p>

						<div className={styles["pagination"]}>
							<a href='#'>&laquo;</a>
							<a href='#'>1</a>
							<a href='#' class='active'>
								2
							</a>
							<a href='#'>3</a>
							<div/> */}
      {/* <a href="#">4</a>
              <a href="#">5</a>
              <a href="#">6</a>
              <a href="#">&raquo;</a>
            </div>
          </div>
          <div className={styles["Card1"]}>
            <div className={styles["card-image1"]}>
              <img src={event1} className={styles["img"]} alt="img" />
              <p>Lorem ipsum dolor sit amet</p>
              <button> read more</button>
            </div>
          </div>
          <div className={styles["Card1"]}>
            <div className={styles["card-image1"]}>
              <img src={event2} className={styles["img"]} alt="img" />
              <p>Lorem ipsum dolor sit amet, dolor sit amet, consectetur</p>
              <button> read more</button>
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles["icons"]}>
        <img src={lv} alt="asdasd" />
        <img src={lf} alt="asdasd" />
        <img src={la} alt="asdasd" />
      </div>
      <div className={styles["email"]}>
        <div className={styles["Con"]}>
          <div className={styles["mail"]}>
            <p>
              Stay up to date on all about our latest news, updates and
              adventures.
            </p>
          </div>
          <div>
            <input
              className={styles["inputt"]}
              type="email"
              placeholder="Enter your email"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Home;
